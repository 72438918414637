import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/about/supercells',
    component: () => import('@/views/about/About.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/about/distribution',
    component: () => import('@/views/about/Distribution.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/about/use',
    component: () => import('@/views/about/Use.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/about/pledge',
    component: () => import('@/views/about/Pledge.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/ecology/chain',
    component: () => import('@/views/ecology/IndustrialChain.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/ecology/partner',
    component: () => import('@/views/ecology/Partner.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/ecology/scwallet',
    component: () => import('@/views/ecology/ScWallet.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/ecology/community/interaction',
    component: () => import('@/views/ecology/CommunityInteraction.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/foundation/supercells',
    component: () => import('@/views/foundation/Index.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/foundation/govern',
    component: () => import('@/views/foundation/Govern.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/foundation/influence',
    component: () => import('@/views/foundation/Influence.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/foundation/ico',
    component: () => import('@/views/foundation/Ico.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/foundation/economics',
    component: () => import('@/views/foundation/Economics.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/road',
    component: () => import('@/views/Road.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/contact-us',
    component: () => import('@/views/Contact.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/detail',
    component: () => import('@/views/foundation/Detail.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/govern',
    component: () => import('@/views/foundation/Govern111.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

const DEFAULT_TITLE = 'Life Sciences Cells Bank Foundation';
router.beforeEach(async (to, from, next) => {
  document.title = DEFAULT_TITLE + (to.meta.title || '');
  next()
  // if (to.meta.requireAuth) {
  //   if (authAccount) {
  //     next();
  //   } else {
  //       next({
  //         path: '/auth',
  //         query: { redirect: to.fullPath },
  //       });
  //   }
  // } else {
  //   next()
  // }
});

export default router
